import type { Customer } from '@medusajs/medusa';
import { Link } from '@remix-run/react';
import type { UserLocation } from '@storefront/ui-components/hooks/useUserLocation';
import { OutlineLocationOnIcon } from '@storefront/ui-components/shared/icons';
import type { FC, PropsWithChildren } from 'react';
import { useStorefrontSettings } from '~/utils/use-storefront-settings';
import InstantSearchWrapper from '../../../app/components/layout/InstantSearchWrapper';
import type { TogglePayload, ToggleableTargets } from '../providers/storefront-provider';
import { IconButton } from '../shared/buttons';
import { Image } from '../shared/images/Image';
import { HeaderUserMenu } from './HeaderUserMenu';
import { SearchBar } from './SearchBar';

export interface HeaderProps {
  customer?: Customer;
  location?: UserLocation;
  toggleSearch?: (isOpen: boolean) => void;
  toggleLocation?: (payload?: TogglePayload<ToggleableTargets.location>) => void;
  isSearchVisible?: boolean;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({
  customer,
  location,
  toggleSearch,
  toggleLocation,
  isSearchVisible = true,
}) => {
  const storefrontSettings = useStorefrontSettings();

  return (
    <nav className="nav-bar flex h-12 sm:h-16 w-full items-center justify-between gap-2 sm:gap-4 border bg-white px-4 sm:px-8">
      <Link to="/">
        <>
          <Image alt="primary-logo" src={storefrontSettings?.logo?.url} className="h-5 min-w-36 hidden sm:block" />
          <Image alt="primary-logo" src={storefrontSettings?.icon?.url} className="h-5 block sm:hidden" />
        </>
      </Link>
      <InstantSearchWrapper>
        <div className="flex flex-row align-middle items-center justify-between w-full gap-2">
          <div className="flex flex-grow xs:justify-center md:justify-end xs:ml-6">
            {toggleSearch && isSearchVisible && <SearchBar />}
          </div>
          <div className="flex items-center gap-2">
            {location && toggleLocation && (
              <IconButton
                className="header-postal-code hidden sm:inline-flex h-9 sm:h-10"
                icon={OutlineLocationOnIcon}
                prefix={location.postalCode}
                onClick={() => toggleLocation({ open: true })}
              />
            )}

            {(customer || (location && toggleLocation)) && (
              <HeaderUserMenu customer={customer} location={location} toggleLocation={toggleLocation} />
            )}
          </div>
        </div>
      </InstantSearchWrapper>
    </nav>
  );
};
