import { Header as HeaderComponent } from '@storefront/ui-components/header/Header';
import { useStorefrontSettings } from '~/utils/use-storefront-settings';

export const SimpleHeader = ({ isSearchVisible }: { isSearchVisible: boolean }) => {
  const store = useStorefrontSettings();

  if (!store) return <>Loading...</>;

  return (
    <header id="mkt-header" className="sticky top-0 z-40">
      <nav aria-label="Top">
        <div className="bg-white">
          <HeaderComponent isSearchVisible={isSearchVisible} />
        </div>
      </nav>
    </header>
  );
};
