import { LoginModal } from '@storefront/ui-components/auth/login/LoginModal';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

import { SimpleHeader } from '../header/SimpleHeader';

export interface PageProps {
  className?: string;
  children: ReactNode;
}

export const CheckoutLayout: FC<PageProps> = ({ className, children }) => {
  return (
    <div className={clsx('page-layout flex min-h-screen flex-col bg-gray-50', className)}>
      <SimpleHeader isSearchVisible={false} />
      <main className="flex-auto">
        <div className="w-full">{children}</div>
      </main>
    </div>
  );
};
